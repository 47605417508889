:root {
  --themeColor:#e0262a;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* my css */

@font-face {
  font-family: md sans;
  src: url('./font/Sequel Sans Light Body.otf');
}



@font-face {
  font-family: campton;
  src: url('./newfont/CamptonBlack.otf');
}



/* @font-face {
  font-family: md sans;
  src: url('./font3/Blackout.otf');
} */



.base
{
  min-height: 100vh;
  padding-top: 100px;
  padding-bottom: 60px;
  /* background: linear-gradient(216.04deg, #27C0EF 13.84%, #00A8DB 83.53%); */
}
.hp2img
{
  /* max-height: 10%; */
  /* background-color: #282c34; */
}



.shiw:hover
{
  text-shadow: -3px -1px 6px rgba(0, 0, 124, 0.38);
}

.gfd{
  color: #282c34;
}
.gfd:hover
{
  text-shadow: -3px -1px 6px rgba(0, 0, 124, 0.38);
}
.text-left {
  text-align: left;
}
.base3
{
}
.animated
{
justify-content: center;
}
.a1 
{
}
.iphone1
{
  max-height: 100%;
  max-width: 100%;
  
}
.iphone2
{
  max-height: 100%;
  max-width: 100%;
}.iphone3
{
  max-height: 100%;
  max-width: 100%;
  
}.iphone4
{
  max-height: 100%;
  max-width: 100%;
  
}
.iphone5
{
  max-height: 100%;
  max-width: 100%;
  
}
.a2 
{
  background-color: #034D82;
}
.a3 
{
} 
.a4 
{
  background-color: #00A8DB;
}
.a5 
{
  background-color: #282c34;
}
img
{
  max-width: 100%;
}
.logoimg
{
  height: 70px;
}
.airbnb
{
  margin-left: 20px;
}
.uber 
{
}
.logoimguber
{
}
.logop1
{
  display: flex;
  justify-content: center;
}
.logop2
{
  display: flex;
  justify-content: center;
}
.logolist
{
 padding-top: 20px; 
 justify-content: center;
}
.logocont
{
  display: flex;
  background: rgba( 115, 115, 115, 0.25 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 12.5px );
  -webkit-backdrop-filter: blur( 12.5px );
  border-radius: 10px;
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  
}
.codeomatics
{
  height: 100px;
}
.hp1cont
{
  
  display: flex;
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 3rem;
}
.hp1txtcont
{
}
.hp1txt
{
  /* font-family: campton; */
  font-weight: 700;
  font-size: 53px;
  color: #4e4039;
}
.ai-center{
  align-items: center;
}
.jc-center {
  justify-content: center;
}
.contactNav {
  background: var(--themeColor);
  border: 2px solid var(--themeColor);
  color: white;
  /* height: 80px; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  padding: 10px;
  width: 150px;
  /* width: 100%; */
  text-decoration: none;
  border-radius: 25px;
  cursor: pointer;
}
.contactNav:hover {
  color: #000 !important;
}
.themeBtn {
  background: var(--themeColor);
  border: 2px solid var(--themeColor);
  color: white;
  /* height: 80px; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  padding: 10px;
  width: 160px;
  /* width: 100%; */
  text-decoration: none;
  border-radius: 25px;
}
.themeBtn:hover {
  background: #fff;
  border: 2px solid var(--themeColor);
  color: var(--themeColor) ;
}
.themeBtnOutline {
  background: #fff;
  border: 2px solid var(--themeColor);
  color: var(--themeColor);
  /* height: 80px; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  padding: 10px;
  width: 160px;
  /* width: 100%; */
  text-decoration: none;
  border-radius: 25px;
}
.themeBtnOutline:hover {
  color: white;
  background: var(--themeColor) ;
  border: 2px solid var(--themeColor) ;
}
.navbar .nav-item a {
  color: white;
  font-weight: 500;
  padding: 10px;
  margin: 0 10px;
  position: relative;
}
.navbar .nav-item a:hover {
  color: var(--themeColor);
}
.navbar .nav-item a:hover::after {
  content: '';
  height: 2px;
  width: 40px;
  background-color: var(--themeColor);
  position: absolute;
  transform: translate(-50%,0);
  left: 50%;
  bottom: 0;
}

.navbar.downNav {
  background: black;
}
.navbar.downNav .nav-item a {
  color: #fff;
}
.navbar.downNav .nav-item a:hover {
  color: var(--themeColor);
}
.hp1txt span {
  color: var(--themeColor) !important;
}
.hp1para
{
  font-family: md sans;
  font-size: 16px;
  color: #424154;
  margin-top: 25px;
}
.hiw
{
  position: relative;
  height: 50px;
  width: 180px;
  border-radius: 10px;
  background-color: white;
  
  border: none;
  color:var(--themeColor)!important;
  
}
.fd
{
  position: relative;
  color: white;
  border: none;
  background: none;
}
.hp2cont
{
  max-width: 480px;
  text-align: right;
  margin-left: auto;
  position: relative;
}
.hp2cont .star {
  position: absolute;
  left: 10px;
  top: 20px;
  width: 24%;
  animation: rotate 7s linear infinite;
}
@keyframes rotate {
  0%{
     transform: rotate(0deg);
    }
 100%{
     transform: rotate(360deg);
    }
 }
.base2
{
  padding: 50px ;
  /* background-color:white; */
}

.pg2cont
{
  display: flex;
}
.pg21
{
  display: flex;
  /* justify-content: center; */
  justify-content: flex-start;
  align-items: center;
}
.jc-fe {
  justify-content: flex-end !important;
}
.pg22
{
  display: flex;
  justify-content: center;
  align-items: center;
}
.iphoneimg
{
  /* height: 400px; */
  max-width: 100%;


}
.mobcont
{
  max-width: 100%;
  /* background-image: url('./images/dottedline.png'); */
  /* background-size: cover;
  background-repeat: no-repeat; */
  max-width: 400px;

}
.pg22txt
{
  width: 90%;

}
.p2head
{
  /* font-family: campton; */
  font-size: 45px;
  /* color: #034D82; */
  color: #4e4039;
font-weight: bold;
line-height: 52px;
letter-spacing: 0.3px;
margin-bottom: 60px;
position: relative;
text-align: center;
}
.p2head:after {
  content: "";
  height: 6px;
  width: 170px;
  position: absolute;
  transform: translate(-50%);
  left: 50%;
  bottom: -25px;
  background: var(--themeColor);
}
.p2head span {
  color: var(--themeColor);
}
.p2para
{
  font-family: md sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0.3px;
  color: #424154;

}
.p2-btn
{
  border: none;
  background: none;
}
.btn-txt
{
  font-family: md sans;
  font-weight: 700;
  font-size: 16px;
  line-height: 20.83px;
  color: var(--themeColor)!important;

}
.up
{
  height: 50vh;
  display: flex;

}
.manageSec {
  padding: 60px 0;
}
.uptxt
{
  /* padding-bottom: 100px; */
  display: flex;
  justify-content: center;
  align-items: center;

}
.uppic
{
  padding-top: 20px;
display: flex;
justify-content: center;
align-items: center;
}
.imgbox
{
  display: flex;
  justify-content: center;
  align-items: center;
}
.laptop
{
  max-width: 100%;
  height: auto;
}
.uphead
{
  font-family: campton;
  font-weight: bold;
  font-size: 55px;
  line-height: 52px;
  letter-spacing: 0.3px;
  color: #034D82;

}
.uppara
{
  font-family: md sans;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  color: #424154;

}
.upitems
{
  font-family: md sans;
  font-weight: 400;
  font-size: 16px;
  line-height: 20.83px;
  letter-spacing: 0.3px;
  color: #424154;
  margin-bottom: 10px;
}
.up-btn
{
  font-family: md sans;
  font-weight: 700;
  font-size: 16px;
  line-height: 20.83px;
  /* color: #04AADD; */
  color: var(--themeColor);

}
.upbutton
{
  background: none;
  border: none;
}
.navbar
{
  z-index: 999;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  /* background: rgba( 205, 255, 255, 0.25 ); */
  /* background: #040F28!important; */
/* box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 ); */
backdrop-filter: blur( 12.5px );
-webkit-backdrop-filter: blur( 12.5px );
border: 1px solid rgba( 255, 255, 255, 0.18 );
}
.navbar .nav-item a {
  color: #000;
  font-weight: 500;
  text-decoration: none;
}
.lt{
  
  width: 200px;
  border-radius: 50px;
  height: 50px;
  border: 2px solid white;

}
.lt:hover{
  background-color:#00A8DB;
}

.down
{
  height: 50vh;
}

.txtcontp2
{
  /* height: 90%; */
  width: 85%;
}



.cardsbase
{
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cardcont
{
  justify-content: space-evenly;
  display: flex;

}
.cardsbase
{
  padding: 60px 0;
  max-width: 880px;
  margin: auto;
}
.PUMPIFY
{
  font-weight: bold;
}
.cbtxt
{
  margin-top: 40px;
  font-family: campton;
  font-weight: bold;
  font-size: 55px;
  line-height: 52px;
  letter-spacing: 0.3px;
  color: #034D82;
}
.cbpara
{
  font-family: md sans;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.3px;
  color: #424154;

}
.cards1
{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.cards2
{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.c1
{
  /* margin-top: 20px; */
  /* height: 270px; */
  /* width: 200px; */
  padding: 20px;
  text-align: center;
  border-radius: 25px;
  background-color: #f5f5f5;
  position: relative;
  top: 0;
  transition: all 0.3s;
  margin-bottom: 25px;
}
.c1:hover {
  /* border-bottom: 5px solid var(--themeColor); */
  top: -5px;
  transition: all 0.3s;
}
.c1logo
{
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
  height: 70px;
  width: 70px;
}
.c1logo img{
  max-height: 100%;
  max-width: 100%;
}
.c2
{
  margin-top: 20px;
  border-radius: 10px;
  height: 270px;
  width: 200px;
  background-color: white;
}
.c2logo
{
  background-image: url('./images/logo2.png');
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
  height: 70px;
  width: 70px;
}
.c3
{
  margin-top: 20px;
  border-radius: 10px;
  height: 270px;
  width: 200px;
  background-color: white;
}
.c3logo
{
  background-image: url('./images/logo3.png');
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  margin-top: 15px;
  /* margin-left: 55px; */
  margin-left: auto;
  margin-right: auto;
  height: 70px;
  width: 70px;
}
.c4
{
  margin-top: 20px;
  border-radius: 10px;
  height: 270px;
  width: 200px;
  background-color: white;
}
.c4logo
{
  background-image: url('./images/logo4.png');
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
  margin-top: 15px;
  /* margin-left: 55px; */
  margin-left: auto;
  margin-right: auto;
  height: 70px;
  width: 70px;
}
.cardhead
{
  margin-top: 20px;
  font-family: md sans;
  font-weight: 700;
  font-size: 20px;
  color: #034D82;
    
}
.cardpara
{
  margin-top: 20px;
  font-family: md sans;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.3px;
  color: #424154;
}
.b5head
{
  display: flex;
  justify-content: center;
  align-items: center;
}
.headingb5
{
  width: 80%;
}
.b5slider
{
  display: flex;
  justify-content: center;
  align-items: center;
height: 300px;
}
.heading_base5
{
  /* font-family: campton; */
  /* color: #034D82; */
  color: #4e4039;
  font-weight: 600;
  font-size: 40px;
  line-height: 52px;
  letter-spacing: 0.3px;
  max-width: 900px;
  margin: auto;
  text-align: center;
  margin-bottom: 50px;

}
.para-base5
{
  font-family: md sans;
  font-weight: 400;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: 0.3px;
  color:#424154;
}
.powerSec {
  padding: 50px 0;
  background: #f5f5f5;
  margin: 40px 0;
}
.powerSec .powerCard {
  padding: 25px;
  background: white;
  border-radius: 25px;
}
.powerSec .powerCard .desc{
  font-size: 19px;
  margin: 22px 0;
}
.powerSec .powerCard .profile{
  display: flex;
  align-items: center;
}
.powerSec .powerCard .profile .imgdiv{
  width: 65px;
  height: 65px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 18px;
}
.powerSec .powerCard .name {
  font-size: 20px;
  color: #101a08;
}
.powerSec .powerCard .desig {
  color: #888888;
  font-size: 14px;
}

.slidercont
{
  
  width: 90%;
}
.user_imgbox
{
  margin-left: 10px;
  min-width: 70px;
  background-color: #034D82;
}
.userdata
{
  margin-left: 10px;
  width: 300px;
}
.arrow1
{
  background: none;
  border: none;
  margin-top: 10px;
  height: 50px;
  width: 50px;
  margin-left: 10px;
  border-radius: 50px;
}
.arrow2
{
  background: none;
  border: none;
  margin-top: 10px;
  height: 50px;
  width: 50px;
  margin-left: 10px;
  border-radius: 50px;
}
.arrow2:hover
{
  color: white;
  background-color: #034D82;
}
.arrow1:hover
{
  color: white;
  background-color: #034D82;
}
.slider 
{
  display: flex;
  flex-direction: row;
}
.userheading
{
  font-family: md sans;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color:#034D82;
}
.userdescription
{
font-family: md sans;
font-weight: 400;
font-size: 16px;
line-height: 26px;
color: #424154;
}
.navoptions
{
}
.home 
{ 
}
.navcont
{
  /* width: 35%; */
  display: flex;
  justify-content: space-between;
}

.nav-txt
{
  font-family: md sans;
  font-weight: 500;
  font-size: 14px;
  line-height: 18.23px;
  letter-spacing: 0.3px;
  color: black;
}

.footer
{
text-align: center;
  background-color: black;
  justify-content: center;
  align-items: center;
}

.socialbox
{
  width: 300px;
  display: flex;
  justify-content: space-between;
}

.dapp
{
  font-family: md sans;
  font-weight: 700;
}
.gyb
{
  height: 300px;
  justify-content: center;
  align-items: center;

}
.bluecard
{

  /* display: flex; */
  /* align-items: center; */
  /* flex-direction: row; */
  /* height: 50%; */
/* width: 80%; */
/* background: rgb(92,12,236); */
/* background: linear-gradient(270deg, rgba(92,12,236,1) 0%, rgba(31,203,255,1) 100%); */
/* background: linear-gradient(270deg, rgb(253 79 0) 0%, rgb(255 199 162) 100%); */
border-radius: 10px;  
}
.connect-btn
{
  margin-right: 20px;
  margin-left: auto;
  border: 2px solid white;
  background-color: white;
  width: 200px;
  height: 60px;
  border-radius: 30px;
}
.connect-btn:hover{
  background-color: #FB5B21;
}

.blu-txt
{
  /* font-family: campton; */
  text-align: center;
  font-weight: 600;
  color: #000000;
  font-size: 50px;
}
.footer{
}
.footcont
{
  padding-top: 20px;
  display: flex;
width: 95%;
}
.ftxt
{
  color: black;
  text-decoration: none;
  font-family: md sans;
  font-size: 22px;
}
.ftxt2
{
  color: black;
  text-decoration: none;
  font-family: md sans;
  font-size: 14px;
}
hr
{
  opacity: 100%;
  border-top: 1px solid gray;
  
}
.point1
{
  width: 400px;
  max-width: 100%;
  display: flex;
}
.point2
{
  margin-top: 10px;
  width: 400px;
  max-width: 100%;
  display: flex;
}
.point3
{
  margin-top: 10px;
  width: 400px;
  max-width: 100%;
  display: flex;
}
.point4
{
  margin-top: 10px;
  width: 400px;
  max-width: 100%;
  display: flex;
}
.pointtick
{
  margin-right: 10px;
}
.pointtick img
{
  width: 25px;
}
.pointtick span
{
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--themeColor);
  display: inline-block;
}
.pointtxt
{
  height: 100%;
  width: 90%;
}
.socialnetworks
{
height: 70px;
display: flex;
justify-content: space-evenly
}
.fb
{
  margin-right: 10px;
}
.twi
{
  margin-right: 10px;
}
.insta
{
  margin-right: 10px;
}
.sociallogo
{
  margin-top: 20px;
height: 40px;
width: 70px;
}
.email
{
}
.pumplogo
  {
    height: 100px;
  }

  .pumplogonav
  {
    padding-top: 5px;
    height: 80px;
    padding: 7px;
  }

.logo1
{
  height: 50px;
}

  .socialapps
  {
    margin-left: auto;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .icon
  {
    height: 100%;
  }
.logocontainer
{
  display: flex;
  flex-direction: column;
}
.contact-details
{
  display: flex;
  flex-direction: row;
}
.phone 
{
/* mar */
}
.mail 
{
margin-left: 20px;
}
.userdata12
{
  justify-content: center;
}
.slide1-btn
{
  opacity: 100%;
  /* background-color: #5572f2; */
  background-color: var(--themeColor);
border-radius: 30px;
}

.slide
{
  width: 100%;
  display: flex;
  justify-content: center;
}
.teaminfo
{
  margin-left: 70px;
  width: 70%;
}

.footer {
  text-align: left;
}
.footer p {
  color: #bdbdbf;
}
.footer .text-primary {
  color: #FB5B21 !important;
}
.footer a:hover {
  color: white;
}
.footer a {
  text-decoration: none;
  color: #bdbdbf;
}
.footer .text-secondry:hover {
  color: #fff;
}
.footer .text-secondry:hover {
  color: #fff;
}
.footer .text-secondary {
  color: #bdbdbf !important;
  text-decoration: none;
}
.footer .bg-primary {
  background-color: var(--themeColor) !important;
}
.carousel-control-next-icon {
  background-size: 15px !important;
}
.carousel-control-prev-icon{
  background-size: 15px !important;
}
.carousel-control-next-icon, .carousel-control-prev-icon {
  width: 1.5rem;
  height: 1.5rem;
}
.btn-square {
  width: 38px;
  height: 38px;
}
.footer .btn-primary {
  color: white;
  background: var(--themeColor);
  border-color: var(--themeColor);
}
.footer .btn-primary:hover {
  color: black;
  background: var(--themeColor);
  border-color: var(--themeColor);
}
.contactModal {
  /* padding: 0 !important; */
  display: flex;
  align-items: center;
  padding-bottom: 30px;
}
.contactModal .heading h2{
  color: black;
  font-weight: 700;
}
.contactModal .heading i{
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
}
.contactModal .heading {
  /* background: url(./images/contactBg.webp); */
  background-size: cover;
  background-position: right;
  padding: 30px 30px 10px 30px;
}
.contactModal .mainContent {
  padding: 30px;
  /* background-color: #fef8f5; */
  border-radius: 12px;
  box-shadow: 0px 1px 7px 0px rgb(0 0 0 / 25%);
}
.contactModal .mainContent .contact{
  font-size: 30px;
  color: black;
  line-height: 1.2;
}

.contactModal .mainContent .socialMain{
  text-align: center;
  margin: 20px 0;
}
.contactModal .mainContent .socialMain a{
  text-decoration: none;
  color: transparent;
}
.contactModal .mainContent i {
  padding: 0px 10px;
}

.contactModal .mainContent .fb {
  color: #FB5B21;
}
.contactModal .mainContent .fb:hover {
  color: #4867AA;
  cursor: pointer;
  /* text-shadow: 0px 7px 10px rgba(0, 0, 0, 0.4); */
  transition: all ease-in-out 150ms;
}
.contactModal .mainContent label {
  font-size: 13px;
  margin-bottom: 6px;
}
.contactModal .modalImg {
  margin-bottom: 20px;
}
.modal-fullscreen {
  min-height: 100vh !important;
  height: auto !important;
}
.modal-fullscreen .modal-content {
  min-height: 100vh;
}


.contactModal .mainContent .twitter {
  color: #FB5B21;
  cursor: pointer;
  /* text-shadow: 0px 7px 10px rgba(0, 0, 0, 0.4); */
  transition: all ease-in-out 150ms;
}

.contactModal .mainContent .twitter:hover {
  color: #1DA1F2;
  /* margin-top: -10px; */
  /* text-shadow: 0px 16px 10px rgba(0, 0, 0, 0.3); */
  /* transform: translate(0, -8); */
}

.contactModal .mainContent .git {
  transition: all ease-in-out 150ms;
  color: transparent;
  background: #FB5B21;
  background-clip: text;
  -webkit-background-clip: text;
  font-weight: bold;
}

.contactModal .mainContent .git:hover {
  background: -webkit-radial-gradient(30% 107%, circle, #ad9b35 0%, #cfbb45 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  background: -o-radial-gradient(30% 107%, circle, #ad9b35 0%, #cfbb45 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  background: radial-gradient(circle at 30% 107%, #ad9b35 0%, #cfbb45 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  background: -webkit-radial-gradient(circle at 30% 107%, #ad9b35 0%, #cfbb45 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  /* margin-top: -10px; */
  /* text-shadow: 0px 14px 10px rgba(0, 0, 0, 0.4); */
  /* transform: translate(0, -5); */
}
.contactModal .mainContent i:nth-child(4) {
  color: #fffc00;
  cursor: pointer;
  text-shadow: 0px 7px 10px rgba(0, 0, 0, 0.4);
  transition: all ease-in-out 150ms;
}
.contactModal .mainContent i:nth-child(4):hover {
  margin-top: -10px;
  text-shadow: 0px 16px 10px rgba(0, 0, 0, 0.3);
  transform: translate(0, -8);
}


.contactModal .mainContent .contentDiv{
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.contactModal .mainContent .contentDiv .imgDiv{
 margin-right: 8px;
}
.contactModal .mainContent .contentDiv .imgDiv i{
 font-size: 25px;
 color: #FB5B21;
}
.contactModal .mainContent .contentDiv .imgDiv img{
  width: 25px;
}
.contactModal .mainContent .contentDiv .contactDiv p{
  color: #707070;
  font-size: 13px;
  margin: 0;
}
.contactModal .mainContent .contentDiv .contactDiv a{
  color: #FB5B21;
  font-weight: bold;
}
.contactModal .form-control{
  border: 1px solid #A9A9A9;
  border-radius: 8px;
  min-height: 38px;
}
.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(226 0 0 / 15%) !important;
}
.contactModal input{
  color: #707070;
  font-size: 13px;
  font-weight: 500;
}
.contactModal textarea::placeholder{
  color: #707070;
  font-size: 13px;
  font-weight: 500;
}
.contactModal .sendBtn{
  background-color: var(--themeColor);
  border-color: var(--themeColor);
  color: white;
  font-weight: 500;
  padding: 8px 38px;
  border-radius: 20px;
}
.contactModal .form-group {
  margin-bottom: 12px;
}
.navbar
{
  height: auto;
}
.dNone-lg {
  display: flex;
}
.dFlex-lg {
  display: none;
}
.footer .fMain {
  padding-left: 3rem;
  padding-right: 3rem;
}
.footer .newslMain {
  padding: 3rem;
}
.givYourSec {
  position: relative;
  margin: 70px 0;
}
.givYourSec .effect1{
  position: absolute;
    width: 50%;
    height: 100%;
    z-index: -1;
    background: #f5f5f5;
    right: 0;
    border-top-left-radius: 70px;
}
.givYourSec .effect2{
  position: absolute;
    width: 50%;
    height: 100%;
    z-index: -1;
    background: #f5f5f5;
    left: 0;
    border-top-right-radius: 70px;
}
.contactMM .contactModal .mainContent {
  box-shadow: none;
}
.navbar-toggler-icon {
  filter: invert(1);
}

.privacyPage {
  padding: 50px 0;
}
.privacyPage a{
  color: black;
}
.privacyPage .logoDiv {
  width: 130px;
  margin-bottom: 30px;
}

@media (min-width: 991.98px){
  .demoModal .modal-dialog {
    max-width: 70% !important;
  }
  .credit {
      background: var(--themeColor);
      margin-right: -3rem;
  }
  .navbar
  {
    height: 80px;
  }
  .contactNav {
    /* margin-right: -16px !important; */
  }
  .dNone-lg {
    display: none !important;
  }
  .dFlex-lg {
    display: flex;
  }
}

@media screen and (min-width:350px) and (max-width: 500px) {
  
  .fd{
    margin-left: -9 px
  }
  .logocontainer
  {
    padding-bottom: 20px;
  }
  .hp1txt
  {
    font-size: 50px;
  }
  .connect-btn
  {
    margin-right: 0px;
    margin-left: auto;
  }
  .p2head
  {
    font-size: 40px;
  }
  .uphead
  {
    font-size: 40px;

  }
  .upitems
  {
    margin-left: 10px;
  }
  .cbtxt{
    font-size: 40px;

  }
  .blu-txt
  {
    font-size: 20px;
    margin-top: 20px;

  }
  .socialapps
  {
    justify-content: center;
  }
  .imgbox
  {
    /* height: 200px; */
  }
  .sociallogo
  {
    width: 40px;
  }
  .iphoneimg{
    margin-top: 50px;
    /* height: 400px; */
  }
}

@media screen and (min-width:300px) and (max-width:990px) {
  .pumplogo
  {
    height: 70px;
  }
  .pumplogonav
  {
    height: 70px;
  }
}
@media (min-width: 768px){

  .contactMM .modal-dialog {
      max-width: 720px!important;
  }
  .footer .bg-primary {
    margin-right: -3rem;
  }
}
@media (max-width: 768px){

  .pg22txt {
    width: 100%;
  }
  .p2head {
    font-size: 40px;
  }
  .iphoneimg {
    margin-top: 30px;
  }
  .laptop {
    margin-bottom: 30px;
  }
  .powerSec .powerCard {
    margin-bottom: 20px;
  }
  .givYourSec .effect1{
    background: #fff;
  }
  .givYourSec .effect2{
    background: #fff;
    
  }
}
@media (max-width: 992px){
  .navcont {
    align-items: flex-start;
  }
  .navcont li {
    display: inherit;
  }
  .contactNav {
    height: 50px;
  }
  .footer p {
    margin-bottom: 10px;
  }
  .credit {
    margin-top: 5px;
  }
}
@media (max-width: 576px){
  .givYourSec {
    margin: 0;
  }

  .bluecard {
    flex-direction: column;
    align-items: center;
  }
  .connect-btn {
    margin: auto;
  }
  .blu-txt {
    padding-top: 20px;
    font-size: 24px;
  }
  .hp1cont {
    margin-left: 0;
  }
  .hp1txt {
    font-size: 40px;
  }
  .hp2img {
    margin-top: 20px;
  }
  .base2 {
    padding-left: 0;
    padding-right: 0;
  }
  .p2head {
    font-size: 33px;
  }
  .heading_base5 {
    font-size: 33px;
  }
  .footer .fMain {
    padding-left:0;
    padding-right: 0;
  }
  .mx-xs-0 {
    margin-left: 0;
    margin-right: 0;
  } 
  .footer .newslMain {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-right: -24px;
    margin-left: -24px;
  } 
  .contactModal .mainContent {
    padding: 10px;
  }
}